<picture>
  @if (!imageHasError) {
    <source [attr.srcset]="src" [type]="config.docType" />
  }
  <img
    [src]="src"
    (error)="errorImg($event)"
    class="{{
      imageHasError ? 'swiper-lazy imageHasErrorBackground' : 'swiper-lazy'
    }}"
    [alt]="alt"
    [attr.loading]="config.loading" />
</picture>
