export class Constants {
  // NODE COUNTRY
  public static readonly NODE_ARGENTINA = 'ar';
  public static readonly NODE_URUGUAY = 'uy';
  public static readonly NODE_ECUADOR = 'ec';

  // GEOLOCATION
  public static readonly DEFAULT_CENTER_COORDS = {
    lat: -34.6080512,
    lng: -58.3724665,
  };

  // ELEMENTS UI STYLES
  public static readonly BUTTON_COLOR_PRIMARY_SOLID =
    'button-color-primary-solid';

  public static readonly BUTTON_COLOR_COLLECTION_SOLID =
    'button-color-collection-solid';

  public static readonly BUTTON_COLOR_PRIMARY_BORDER =
    'button-color-primary-border';

  public static readonly BUTTON_COLOR_PRIMARY_BASIC =
    'button-color-primary-basic';

  public static readonly BUTTON_COLOR_GREY_BORDER = 'button-color-grey-border';
  public static readonly BUTTON_COLOR_GREY_BASIC = 'button-color-grey-basic';
  public static readonly BUTTON_COLOR_REMAX_COLLECTION =
    'button-color-remax-collection';

  public static readonly BUTTON_HEIGHT_56PX = '56px';
  public static readonly BUTTON_HEIGHT_48PX = '48px';
  public static readonly BUTTON_HEIGHT_40PX = '40px';
  public static readonly BUTTON_HEIGHT_32PX = '32px';
  public static readonly BANNER_BACKGROUND_PRIMARY = 'banner-color-primary';

  // PROPERTY STATUS
  public static readonly PROP_STATUS_ID_ACTIVE = 1;
  public static readonly PROP_STATUS_ID_RESERVED = 2;
  public static readonly PROP_STATUS_ID_NEGOTIATION = 3;
  // PRICE type
  public static readonly PRICE_TYPE_ID_WEEKLY = 1;
  public static readonly PRICE_TYPE_ID_MONTHLY = 2;
  public static readonly PRICE_TYPE_ID_BIANNUAL = 3;
  public static readonly PRICE_TYPE_ID_YEARLY = 4;
  public static readonly PRICE_TYPE_ID_DAILY = 5;
  public static readonly PRICE_TYPE_ID_FORTNIGHTLY = 6;
  // PROPERTY OPERATION
  public static readonly OPERATION_ID_SALE = 1;
  public static readonly OPERATION_ID_RENT = 2;
  public static readonly OPERATION_ID_TEMPORAL = 3;
  public static readonly OPERATION_TYPE_SALE = 'sale';
  public static readonly OPERATION_TYPE_RENT = 'rent';
  public static readonly OPERATION_TYPE_BUY = 'buy';

  // LABEL MENU ITEM
  public static readonly LABEL_MENU_ITEM_HOME = 'home.home';
  public static readonly LABEL_MENU_ITEM_BUY = 'home.buy';
  public static readonly LABEL_MENU_ITEM_SELL = 'home.sell';
  public static readonly LABEL_MENU_ITEM_RENT = 'home.rent';
  public static readonly LABEL_MENU_ITEM_ENTREPRENEURSHIPS =
    'home.emprendimientos';

  public static readonly LABEL_MENU_ITEM_FRANCHISE = 'home.franchise';
  public static readonly LABEL_MENU_ITEM_BLOG = 'home.blog';
  public static readonly LABEL_MENU_ITEM_CONTACT = 'home.contact';

  // CURRENCY
  public static readonly CURRENCY_ID_DOLLAR = 1;
  public static readonly CURRENCY_ID_PESOS_ARS = 2;
  public static readonly CURRENCY_ID_PESOS_UYU = 3;

  public static readonly CURRENCY_SIMBOL = {
    USD: 'U$D',
    ARS: '$',
    UYU: '$',
  };

  // TIPOS DE PROPIEDAD
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_DUPLEX = 1;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_ESTANDAR = 2;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_LOFT = 3;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_MONOAMBIENTE = 4;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_PENTHOUSE = 5;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_PISO = 6;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_SEMIPISO = 7;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_TRIPLEX = 8;
  public static readonly PROPERTY_TYPE_ID_DEPARTAMENTO_PH = 9;
  public static readonly PROPERTY_TYPE_ID_HOTEL = 10;
  public static readonly PROPERTY_TYPE_ID_EDIFICIO = 11;
  public static readonly PROPERTY_TYPE_ID_OTROS_INMUEBLES = 12;
  public static readonly PROPERTY_TYPE_ID_OFICINA = 13;
  public static readonly PROPERTY_TYPE_ID_LOCAL = 14;
  public static readonly PROPERTY_TYPE_ID_TERRENOS_Y_LOTES = 15;
  public static readonly PROPERTY_TYPE_ID_CAMPO = 16;
  public static readonly PROPERTY_TYPE_ID_FONDO_DE_COMERCIO = 17;
  public static readonly PROPERTY_TYPE_ID_COCHERA = 18;
  public static readonly PROPERTY_TYPE_ID_GALPON = 19;
  public static readonly PROPERTY_TYPE_ID_CASA = 20;
  public static readonly PROPERTY_TYPE_ID_CASA_DUPLEX = 21;
  public static readonly PROPERTY_TYPE_ID_CASA_TRIPLEX = 22;
  public static readonly PROPERTY_TYPE_ID_QUINTA = 23;

  // TIPOS DE PROPIEDAD
  public static readonly PROPERTY_TYPE_DEPARTAMENTOS = 'departamentos';
  public static readonly PROPERTY_TYPE_CASAS = 'casas';
  public static readonly PROPERTY_TYPE_DEPARTAMENTO_DUPLEX =
    'departamento_duplex';

  public static readonly PROPERTY_TYPE_DEPARTAMENTO_ESTANDAR =
    'departamento_estandar';

  public static readonly PROPERTY_TYPE_DEPARTAMENTO_LOFT = 'departamento_loft';
  public static readonly PROPERTY_TYPE_DEPARTAMENTO_MONOAMBIENTE =
    'departamento_monoambiente';

  public static readonly PROPERTY_TYPE_DEPARTAMENTO_PENTHOUSE =
    'departamento_penthouse';

  public static readonly PROPERTY_TYPE_DEPARTAMENTO_PISO = 'departamento_piso';
  public static readonly PROPERTY_TYPE_DEPARTAMENTO_SEMIPISO =
    'departamento_semipiso';

  public static readonly PROPERTY_TYPE_DEPARTAMENTO_TRIPLEX =
    'departamento_triplex';

  public static readonly PROPERTY_TYPE_PH = 'ph';
  public static readonly PROPERTY_TYPE_HOTEL = 'hotel';
  public static readonly PROPERTY_TYPE_EDIFICIO = 'edificio';
  public static readonly PROPERTY_TYPE_OTROS_INMUEBLES = 'otros';
  public static readonly PROPERTY_TYPE_OFICINA = 'oficina';
  public static readonly PROPERTY_TYPE_LOCAL = 'local';
  public static readonly PROPERTY_TYPE_TERRENOS_Y_LOTES = 'terrenos_y_lotes';
  public static readonly PROPERTY_TYPE_CAMPO = 'campo';
  public static readonly PROPERTY_TYPE_FONDO_DE_COMERCIO = 'fondo_de_comercio';
  public static readonly PROPERTY_TYPE_COCHERA = 'cochera';
  public static readonly PROPERTY_TYPE_GALPON = 'galpon';
  public static readonly PROPERTY_TYPE_CASA = 'casa';
  public static readonly PROPERTY_TYPE_CASA_DUPLEX = 'casa_duplex';
  public static readonly PROPERTY_TYPE_CASA_TRIPLEX = 'casa_triplex';
  public static readonly PROPERTY_TYPE_QUINTA = 'quinta';
  public static readonly PROPERTY_TYPE_CONSULTORIO = 'consultorio';
  public static readonly PROPERTY_TYPE_CHACRA = 'chacra';
  public static readonly PROPERTY_TYPE_DEPOSITO = 'deposito';
  public static readonly PROPERTY_TYPE_DEPARTAMENTO = 'departamento';

  // TIPOS DE PROPIEDAD -- ECUADOR (del BE ⚠)
  public static readonly PROPERTY_TYPE_EC_DEPARTAMENTO = 'departamento';
  public static readonly PROPERTY_TYPE_EC_CASA = 'casa';
  public static readonly PROPERTY_TYPE_EC_TERRENO = 'terreno';
  public static readonly PROPERTY_TYPE_EC_QUINTA = 'quinta';
  public static readonly PROPERTY_TYPE_EC_HOTEL = 'hotel';
  public static readonly PROPERTY_TYPE_EC_OFICINA = 'oficina';
  public static readonly PROPERTY_TYPE_EC_GALPON = 'galpon';
  public static readonly PROPERTY_TYPE_EC_EDIFICIO_COMPLETO =
    'edificio_completo';

  public static readonly PROPERTY_TYPE_EC_PROPIEDAD_AGRICOLA =
    'propiedad_agricola';

  public static readonly PROPERTY_TYPE_EC_OTRO = 'otro';
  public static readonly PROPERTY_TYPE_EC_VENTA_NEGOCIO = 'venta_negocio';
  public static readonly PROPERTY_TYPE_EC_PARQUEO = 'garage_parqueo';
  public static readonly PROPERTY_TYPE_EC_BAULERA = 'baulera';
  public static readonly PROPERTY_TYPE_EC_PENTHOUSE = 'penthouse';
  public static readonly PROPERTY_TYPE_EC_BANANERA = 'bananera';
  public static readonly PROPERTY_TYPE_EC_CAMARONERA = 'camaronera';
  public static readonly PROPERTY_TYPE_EC_LOCAL_COMERCIAL = 'local_comercial';
  public static readonly PROPERTY_TYPE_EC_GASOLINERA = 'gasolinera';
  public static readonly PROPERTY_TYPE_EC_MINA = 'mina';
  public static readonly PROPERTY_TYPE_EC_TERRENO_COMERCIAL =
    'terreno_comercial';

  public static readonly PROPERTY_TYPE_EC_CONSULTORIO = 'consultorio';
  public static readonly PROPERTY_TYPE_EC_INVERSION = 'inversion';
  public static readonly PROPERTY_TYPE_EC_CENTRO_COMERCIAL = 'centro_comercial';
  public static readonly PROPERTY_TYPE_EC_GIMNASIO = 'gimnasio';
  public static readonly PROPERTY_TYPE_EC_EDIFICIO_COMERCIAL =
    'edificio_comercial';

  public static readonly PROPERTY_TYPE_EC_HOUSE_COMERCIAL = 'house_comercial';

  // FEATURES TYPE
  public static readonly FEATURE_TYPE = {
    Ambientes: 'Ambientes',
    Caracteristicas: 'Características',
    ComYAmen: 'Comodidades y amenities',
    Servicios: 'Servicios',
  };

  // OPORTUNITY
  public static readonly OPORTUNITY_ID_MUY_DESTACADO = 1;
  public static readonly OPORTUNITY_ID_DESTACADO = 2;
  public static readonly OPORTUNITY_ID_REGULAR = 3;
  public static readonly OPORTUNITY_ID_NO_DESTACADO = 4;

  // USERS ROLES
  public static readonly USER_ROLE_AGENT = 'agent';
  public static readonly USER_ROLE_STAFF = 'staff';
  public static readonly USER_ROLE_BROKER = 'broker';

  // USERS ROLES AND TITLE AGENT
  public static readonly USER_AGENT_TITLE_IN_TRAINING = 'En formación';
  public static readonly USER_AGENT_TITLE_AGENT = 'Agente Inmobilario';
  public static readonly USER_AGENT_TITLE_AGENT_LICENSED_BROKER =
    'Agente/Corredor';

  // USERS ROLES AND TITLE BROKER
  public static readonly USER_BROKER_TITLE_OFFICE_OWNER = 'Broker';
  public static readonly USER_BROKER_TITLE_OFFICE_OWNER_LICENSED_BROKER =
    'Broker/Corredor';

  // USERS ROLES AND TITLE STAFF
  public static readonly USER_STAFF_TITLE_ADMIN_STAFF = 'Staff Administrativo';
  public static readonly USER_STAFF_TITLE_LICENSED_BROKER = 'Corredor';
  public static readonly USER_STAFF_TITLE_MANAGER = 'Gerente (Manager)';
  public static readonly USER_STAFF_TITLE_ADMIN_MANAGER =
    'Gerente Administrativo';

  // COMO NOS CONOCISTE
  public static readonly BE_AGENT_SELECT = [
    {
      id: 0,
      value: 'Cartel en una propiedad',
    },
    {
      id: 1,
      value: 'Aviso clasificado',
    },
    {
      id: 2,
      value: 'Publicidad en diarios/revistas/TV',
    },
    {
      id: 3,
      value: 'Publicidad online',
    },
    {
      id: 4,
      value: 'Publicidad en vía pública',
    },
    {
      id: 5,
      value: 'Redes sociales',
    },
    {
      id: 6,
      value: 'Buscadores (Google)',
    },
    {
      id: 7,
      value: 'Por un agente RE/MAX',
    },
    {
      id: 8,
      value: 'Por un conocido',
    },
    {
      id: 9,
      value: 'Conocí una oficina',
    },
    {
      id: 10,
      value: 'Catálogo de franquícias',
    },
  ];

  // Landings y Búsquedas con url armada
  public static readonly argentinaLandings = [
    '/departamentos-en-la-plata',
    '/propiedades-en-la-plata',
    '/casas-en-la-plata',
    '/casas-en-venta-en-la-plata',
    '/casas-en-alquiler-en-la-plata',
    '/casas-en-venta-en-neuquen',
    '/departamentos-en-venta-en-la-plata',
    '/departamentos-en-alquiler-en-la-plata',
    '/terrenos-en-la-plata',
    '/terrenos-en-venta-en-la-plata',
    '/terrenos-en-venta-en-jujuy',
    '/propiedades-en-mar-del-plata',
    '/departamentos-en-mar-del-plata',
    '/casas-en-mar-del-plata',
    '/casas-en-venta-en-mar-del-plata',
    '/casas-en-alquiler-en-mar-del-plata',
    '/casas-en-alquiler-en-neuquen',
    '/departamentos-en-venta-en-mar-del-plata',
    '/departamentos-en-venta-en-neuquen',
    '/departamentos-en-alquiler-en-mar-del-plata',
    '/terrenos-en-mar-del-plata',
    '/terrenos-en-venta-en-mar-del-plata',
    '/propiedades-en-santa-fe',
    '/departamentos-en-santa-fe',
    '/casas-en-santa-fe',
    '/casas-en-venta-en-santa-fe',
    '/casas-en-alquiler-en-santa-fe',
    '/departamentos-en-venta-en-santa-fe',
    '/departamentos-en-alquiler-en-santa-fe',
    '/terrenos-en-santa-fe',
    '/terrenos-en-venta-en-santa-fe',
    '/propiedades-en-salta',
    '/departamentos-en-salta',
    '/casas-en-salta',
    '/casas-en-venta-en-salta',
    '/casas-en-alquiler-en-salta',
    '/departamentos-en-venta-en-salta',
    '/departamentos-en-alquiler-en-salta',
    '/terrenos-en-salta',
    '/terrenos-en-venta-en-salta',
    '/propiedades-en-mendoza',
    '/departamentos-en-mendoza',
    '/casas-en-mendoza',
    '/casas-en-venta-en-mendoza',
    '/casas-en-alquiler-en-mendoza',
    '/departamentos-en-venta-en-mendoza',
    '/departamentos-en-alquiler-en-mendoza',
    '/terrenos-en-mendoza',
    '/terrenos-en-venta-en-mendoza',
    '/propiedades-en-rosario',
    '/departamentos-en-rosario',
    '/casas-en-rosario',
    '/casas-en-venta-en-rosario',
    '/casas-en-alquiler-en-rosario',
    '/departamentos-en-venta-en-rosario',
    '/departamentos-en-alquiler-en-rosario',
    '/terrenos-en-rosario',
    '/terrenos-en-venta-en-rosario',
    '/propiedades-en-cordoba',
    '/departamentos-en-cordoba',
    '/casas-en-cordoba',
    '/casas-en-venta-en-cordoba',
    '/casas-en-alquiler-en-cordoba',
    '/departamentos-en-venta-en-cordoba',
    '/departamentos-en-alquiler-en-cordoba',
    '/terrenos-en-cordoba',
    '/terrenos-en-venta-en-cordoba',
    '/propiedades-en-bariloche',
    '/departamentos-en-bariloche',
    '/casas-en-bariloche',
    '/casas-en-venta-en-bariloche',
    '/casas-en-alquiler-en-bariloche',
    '/departamentos-en-venta-en-bariloche',
    '/departamentos-en-alquiler-en-bariloche',
    '/propiedades-en-palermo',
    '/departamentos-en-palermo',
    '/casas-en-palermo',
    '/casas-en-venta-en-palermo',
    '/casas-en-venta-en-bariloche',
    '/casas-en-alquiler-en-palermo',
    '/departamentos-en-venta-en-palermo',
    '/departamentos-en-alquiler-en-palermo',
    '/propiedades-en-caballito',
    '/departamentos-en-caballito',
    '/casas-en-caballito',
    '/casas-en-venta-en-caballito',
    '/casas-en-alquiler-en-caballito',
    '/departamentos-en-venta-en-caballito',
    '/departamentos-en-alquiler-en-caballito',
    '/propiedades-en-ciudad-de-buenos-aires',
    '/departamentos-en-ciudad-de-buenos-aires',
    '/casas-en-ciudad-de-buenos-aires',
    '/casas-en-venta-en-ciudad-de-buenos-aires',
    '/casas-en-alquiler-en-ciudad-de-buenos-aires',
    '/departamentos-en-venta-en-ciudad-de-buenos-aires',
    '/departamentos-en-venta-en-recoleta',
    '/departamentos-en-alquiler-en-ciudad-de-buenos-aires',
    '/propiedades-en-pilar',
    '/propiedades-en-neuquen',
    '/departamentos-en-pilar',
    '/departamentos-en-neuquen',
    '/casas-en-pilar',
    '/casas-en-neuquen',
    '/casas-en-venta-en-pilar',
    '/casas-en-alquiler-en-pilar',
    '/departamentos-en-venta-en-pilar',
    '/departamentos-en-alquiler-en-pilar',
    '/propiedades-en-belgrano',
    '/departamentos-en-belgrano',
    '/casas-en-belgrano',
    '/casas-en-venta-en-belgrano',
    '/casas-en-alquiler-en-belgrano',
    '/departamentos-en-venta-en-belgrano',
    '/departamentos-en-alquiler-en-belgrano',
    '/propiedades-en-vicente-lopez',
    '/departamentos-en-vicente-lopez',
    '/casas-en-vicente-lopez',
    '/casas-en-venta-en-vicente-lopez',
    '/casas-en-alquiler-en-vicente-lopez',
    '/departamentos-en-venta-en-vicente-lopez',
    '/departamentos-en-alquiler-en-vicente-lopez',
    '/propiedades-en-nordelta',
    '/departamentos-en-nordelta',
    '/casas-en-nordelta',
    '/casas-en-venta-en-nordelta',
    '/casas-en-alquiler-en-nordelta',
    '/departamentos-en-venta-en-nordelta',
    '/departamentos-en-alquiler-en-nordelta',
    '/propiedades-en-san-isidro',
    '/departamentos-en-san-isidro',
    '/casas-en-san-isidro',
    '/casas-en-venta-en-san-isidro',
    '/casas-en-alquiler-en-san-isidro',
    '/departamentos-en-venta-en-san-isidro',
    '/departamentos-en-alquiler-en-san-isidro',
    '/departamentos-en-tigre',
    '/casas-en-tigre',
    '/casas-en-venta-en-tigre',
    '/casas-en-alquiler-en-tigre',
    '/departamentos-en-venta-en-tigre',
    '/departamentos-en-alquiler-en-tigre',
    '/casas-en-venta-en-tandil',
    '/casas-en-alquiler-en-tandil',
    '/departamentos-en-venta-en-tandil',
    '/departamentos-en-alquiler-en-tandil',
    '/casas-en-venta-en-posadas',
    '/casas-en-alquiler-en-posadas',
    '/departamentos-en-venta-en-posadas',
    '/departamentos-en-alquiler-en-posadas',
    '/casas-en-venta-en-jujuy',
    '/casas-en-alquiler-en-jujuy',
    '/departamentos-en-venta-en-jujuy',
    '/departamentos-en-alquiler-en-jujuy',
    '/casas-en-venta-en-san-juan',
    '/casas-en-alquiler-en-san-juan',
    '/departamentos-en-venta-en-san-juan',
    '/departamentos-en-alquiler-en-san-juan',
    '/departamentos-en-alquiler-en-neuquen',
    '/propiedades-en-posadas',
    '/inmuebles',
    '/oficinas',
  ];

  // Landings y búsquedas
  public static readonly uruguayLandings = [
    '/propiedades-en-punta-del-este',
    '/apartamentos-en-punta-del-este',
    '/apartamentos-en-alquiler-en-punta-del-este',
    '/apartamentos-en-venta-en-punta-del-este',
    '/casas-en-punta-del-este',
    '/casas-en-alquiler-en-punta-del-este',
    '/casas-en-venta-en-punta-del-este',
    '/propiedades-en-montevideo',
    '/apartamentos-en-montevideo',
    '/apartamentos-en-alquiler-en-montevideo',
    '/apartamentos-en-venta-en-montevideo',
    '/casas-en-montevideo',
    '/casas-en-alquiler-en-montevideo',
    '/casas-en-venta-en-montevideo',
    '/terrenos-en-uruguay',
    '/terrenos-en-maldonado',
    '/casas-en-alquiler-en-maldonado',
    '/casas-en-venta-en-maldonado',
    '/terrenos-en-rocha',
    '/terrenos-en-canelones',
    '/inmuebles',
    '/locales-comerciales',
    '/locales-comerciales-en-venta',
    '/locales-comerciales-en-alquiler',
  ];

  // EXTENSIONES DE IMÁGENES
  public static readonly DOCTYPE_WEBP = 'webp';
  public static readonly DOCTYPE_GIF = 'gif';
  public static readonly DOCTYPE_SVG = 'svg';
  public static readonly DOCTYPE_JPG = 'jpg';
  public static readonly DOCTYPE_PNG = 'png';

  public static readonly DOCTYPE_WEBM = 'webm';

  // TIPO DE CARPETA PARA GUARDAR LAS IMÁGENES
  public static readonly FOLDER_AGENTS = 'agents';
  public static readonly FOLDER_ASSOCIATES = 'associates';
  public static readonly FOLDER_BROKERS = 'brokers';
  public static readonly FOLDER_ENTREPRENEURSHIPS = 'entrepreneurships';
  public static readonly FOLDER_HOME = 'home';
  public static readonly FOLDER_LISTINGS = 'listings';
  public static readonly FOLDER_OFFICES = 'offices';
  public static readonly FOLDER_REPORT = 'report';
  public static readonly FOLDER_STAFFS = 'staffs';
  public static readonly FOLDER_FAVORITES = 'favorites';

  // STATUS TAGS DE CARDS
  public static readonly STATUS_TAGS_ACTIVE = 'active';
  public static readonly STATUS_TAGS_RESERVED = 'reserved';
  public static readonly STATUS_TAGS_NEGOTIATION = 'negotiation';

  public static readonly STATUS_TAGS_PRESALE = 'presale';
  public static readonly STATUS_TAGS_IN_CONSTRUCTION = 'in_construction';
  public static readonly STATUS_TAGS_INPIT = 'pozo';
  public static readonly STATUS_TAGS_FINISHED = 'finished';

  // LISTING STATUS
  public static readonly LISTING_STATUS_ACTIVE = 'active';

  // Listings results view mode
  public static readonly LISTINGS_VIEW_MODE_LIST = 'listViewMode';
  public static readonly LISTINGS_VIEW_MODE_GRID = 'gridViewMode';
  public static readonly LISTINGS_VIEW_MODE_MAP = 'mapViewMode';

  // Publication type
  public static readonly PUBLICATION_TYPE_LISTINGS = 'listings';
  public static readonly PUBLICATION_TYPE_ENTREPRENEURSHIP = 'emprendimientos';

  // Filters name
  public static readonly FILTER_OPERATION_TYPE = 'operationType';
  public static readonly FILTER_PROPERTY_TYPE = 'propertyType';
  public static readonly FILTER_ROOT_LABEL = 'rootLabel';
  public static readonly FILTER_COUNTY = 'countie';
  public static readonly FILTER_CITY = 'citie';
  public static readonly FILTER_NEIGHBORHOOD = 'neighborhood';
  public static readonly FILTER_PRIVATE_COMMUNITY = 'privatecommunitie';
  public static readonly FILTER_TITLE = 'title';

  public static readonly HECTARES = 'hectáreas';
  public static readonly ONE_HECTARE_TO_METERS = 10000; // 1 hectárea equivale a 10.000 m²
}
